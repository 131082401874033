import * as React from "react"
import BlogContainer from "../components/BlogContainer/BlogContainer"
import Seo from "../components/Seo/Seo"
import { graphql } from "gatsby"
const BlogPage = ({ data: { allWpPost } }) => {
  const posts = allWpPost.nodes

  return (
    <>
      <Seo title="Blog" />
      <BlogContainer posts={posts} hideViewAll />
    </>
  )
}

export default BlogPage

export const query = graphql`
  {
    allWpPost {
      nodes {
        slug
        uri
        title
        excerpt
        categories {
          nodes {
            name
          }
        }
        featuredBlogImage {
          featuredImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 800, quality: 100)
              }
            }
          }
        }
      }
    }
  }
`
